export const RoutersLinks = {
   Login: "/",
   Registrarse: "/registrarse",
   Home: "capacitacion",
   Menu: "menu",
   Capacitacion: ":id",
   Descubre: "http://colgate.com/es-co"
}

export const urlApi = "https://crecerconcolgate.col1.co/api-visita";
